import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect, useRef, useCallback } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { DoctorListDatagrid, AllPatientListDatagrid } from '../../components';
  import Form, {
    Item as FormItem,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule
  } from 'devextreme-react/form';
  import { Toast } from 'devextreme-react/toast';

  
  export default function AddDoctorComponent() {
  
    const { user } = useAuth();

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: 'New doctor has been added',
      });

      function onHiding() {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
        });
      }


    const [formData, setformData] = useState({ doctor: '', doctorpassword: '', name: '', cnname: '', zhname: '', email: '', phone: '', description: '' });

    const formData1 = useRef(null)

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(backendlink + '/operation/adddoctor',
              {
                usertoken: user.usertoken,
                username: formData.doctor,
                password: formData.doctorpassword,
                name: formData.name,
                cnname: formData.zhname,
                zhname: formData.cnname,
                email: formData.email,
                phone: formData.phone,
                description0: formData.description,
              }, 
              {
                headers: {
                  token: user.token
                }        
            }).then((res)=>{
              if (res.data.errorno == 0){
                setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'success',
                    message: 'New doctor has been added',
                });   


                // setformData({ doctor: '', doctorpassword: '', name: '', cnname: '', zhname: '', email: '', phone: '', description: ''  }); 
                // console.log("ss", formData1.current.instance) 
                formData1.current.instance.clear();
                // formData.current.clear();
              }else{
                  setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    type: 'error',
                    message: res.data.errmessage,
                });  
              }  
            });

          } catch (error) {
            // console.log(error);
            alert(error);
          }
      };

    const confirmPassword = useCallback(
        ({ value }) => value === formData.doctorpassword,
        []
      );

    return (
      <React.Fragment>
          <form onSubmit={onSubmit}>
            <Form ref={formData1} 
            formData={formData}
            >
                <FormItem
                title='Doctor'
                dataField={'doctor'}
                editorType={'dxTextBox'}
                >
                    <RequiredRule message="Doctor Login Name is required" />
                    <Label text="Doctor Login Name"/>
                </FormItem>
                <FormItem
                title='Name'
                dataField={'name'}
                editorType={'dxTextBox'}
                >
                    <RequiredRule message="Name is required" />
                    <Label text="Display Name"/>
                </FormItem>
                <FormItem
                title='Traditional Chinese Name'
                dataField={'cnname'}
                editorType={'dxTextBox'}
                >
                    <RequiredRule message="Traditional Chinese Name is required" />
                    <Label text="Traditional Chinese Name"/>
                </FormItem>
                <FormItem
                title='Simplified Chinese Name'
                dataField={'zhname'}
                editorType={'dxTextBox'}
                >
                    <RequiredRule message="Simplified Chinese Name is required" />
                    <Label text="Simplified Chinese Name"/>
                </FormItem>
                <FormItem
                title='Email'
                dataField={'email'}
                editorType={'dxTextBox'}
                // editorOptions={{placeholder:'Username'}}
                >
                    <RequiredRule message="email is required" />
                    <Label />
                </FormItem>
                <FormItem
                title='Phone'
                dataField={'phone'}
                editorType={'dxTextBox'}
                // editorOptions={{placeholder:'Username'}}
                >
                    <RequiredRule message="Phone is required" />
                    <Label />
                </FormItem>
                <FormItem
                    dataField={'description'}
                    editorType={'dxTextArea'}
                    editorOptions={{ height: '100px' }}

                    >
                    <Label text='Description' />
                </FormItem>
                <FormItem
                    dataField={'doctorpassword'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordEditorOptions}
                    >
                    <RequiredRule message="Password is required" />
                    <Label text='Doctor Password' />
                </FormItem>
                <FormItem
                dataField={'confirmedPassword'}
                editorType={'dxTextBox'}
                editorOptions={confirmedPasswordEditorOptions}
                >
                <RequiredRule message="Password is required" />
                <CustomRule
                    message={'Passwords do not match'}
                    validationCallback={confirmPassword}
                />
                <Label />
                </FormItem>
                <ButtonItem>
                <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    useSubmitBehavior={true}
                >
                    Create a Doctor Account
                </ButtonOptions>
                </ButtonItem>
            </Form>
        </form>
        <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={2000}
      />
      </React.Fragment>
    );
  }

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };
