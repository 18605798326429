import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Export,
  HeaderFilter
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { backendlink } from '../../config';
import axios from 'axios';
import { useAuth } from '../../contexts/auth';

export default function CustomerPatientPage() {

  const { user } = useAuth();

  const [users, setUsers] = useState([
    // { id: 1, Activity: 'ausculto', Date: '2022-01-01', Time: '10:00', Details: 'Heart rate: 80 bpm <br/> Classification: Normal', SpecificDetails: "Recognition 1 : 10:20:31pm <br/> Recognition 2 : 10:20:38pm<br/>Recognition 3 : 10:20:43pm     <span style='color:red'>Fail</span> <br/> Recognition 4 : 10:20:50pm<br/>Recognition 5 : 10:20:58pm<br/>Heart rate       : 84bpm<br/>Classification  : 10:21:10pm<br/>" },
    // { id: 2, Activity: 'ausculto', Date: '2022-01-02', Time: '14:00', Details: 'Heart rate: 82 bpm <br/> Classification: Normal', SpecificDetails: "Recognition 1 : 10:20:31pm <br/> Recognition 2 : 10:20:38pm<br/>Recognition 3 : 10:20:43pm     <span style='color:red'>Fail</span><br/> Recognition 4 : 10:20:50pm<br/>Recognition 5 : 10:20:58pm<br/>Heart rate       : 83bpm<br/>Classification  : 10:21:10pm<br/>" },
    // { id: 3, Activity: 'ausculto', Date: '2022-01-03', Time: '18:00', Details: 'Heart rate: 84 bpm <br/> Classification: Abnormal', SpecificDetails: "Recognition 1 : 10:20:31pm <br/> Recognition 2 : 10:20:38pm<br/>Recognition 3 : 10:20:43pm     <span style='color:red'>Fail</span><br/> Recognition 4 : 10:20:50pm<br/>Recognition 5 : 10:20:58pm<br/>Heart rate       : 82bpm<br/>Classification  : 10:21:10pm<br/>" },
  ]);
  const [patient, setpatient] = useState('');
  const [log, setlog] = useState(false)

  const location = useLocation();

  function ChangeLog() {
    setlog(!log);
  }


  useEffect(() => {
    setpatient(location.state.name); // get id

    const fetchData = async () => {
      try {
        const response = await axios.post(backendlink + '/msg/getallmessagelist',
          {
            usertoken: location.state.userToken
          },
          {
            headers: {
              token: user.token
            }
          });
          // console.log(response)
        if (response.data.errorno == 0) {
          const temp = response.data.result;
          const convertedData = temp.map(item => {
            const date = new Date(item.timestamp * 1000);
            const formattedDate = date.toLocaleDateString('en-US');
            const formattedTime = date.toLocaleTimeString('en-US');
            var formattedDetails = "";
            if (Array.isArray(item.bpm) && item.bpm.length === 0) {
              formattedDetails = "Incomplete auscultation";
            } else {
              const ClassificationResult = item.classification[0].classification === 0 ? "Normal" : item.classification[0].classification === 1 ? "Abnormal" : "Failed to calculate";
              formattedDetails = item.bpm[0].bpm !== -1 ? ("Heart rate: " + item.bpm[0].bpm) + " bpm <br /> Classification: " + ClassificationResult : ("Heart rate: Failed to calculate <br /> Classification: " + ClassificationResult);
            }


            const formattedOutput = item.recognition.map((item, index) => {
              const timestamp = new Date(item.timestamp * 1000);
              const formattedDate = timestamp.toLocaleDateString('en-US');
              const formattedTime = timestamp.toLocaleTimeString('en-US');
              const failure = item.success === 1 ? "" : "<span style='color:red'>  Fail</span>"
              return `Recognition ${index + 1}: ${formattedDate} ${formattedTime}` + failure;
            }).join('<br />');

            // console.log("sdasda",formattedOutput)
            const formattedSpecificDetails = formattedOutput + "<br />" + formattedDetails;
            return { ...item, activity: "ausculto", date: formattedDate, time: formattedTime, Details: formattedDetails, SpecificDetails: formattedSpecificDetails };
          });
          // console.log(convertedData)
          setUsers(convertedData.reverse())
        }
        else {
          alert(response.data.errmessage);
        }
        // console.log("sdakl", convertedData)
      } catch (error) {
        // console.log(error);
        alert(error);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Patient:</th>
            <td>{patient}</td>
            <th>Email:</th>
            <td>{location.state.email}</td>
            <th>Age:</th>
            <td>42</td>
            <th>Gender:</th>
            <td>Male</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Patient ID:</th>
            <td>P-233</td>
            <th>Phone:</th>
            <td>{location.state.phone}</td>
            <th>Linked Date:</th>
            <td>12/12/2023</td>
            <th>Clinic/Hospital:</th>
            <td>Queen Mary Hospital</td>
          </tr>
        </tbody>
      </table>
      {/* <h2 className={'content-block'}>Patient: {patient}</h2> */}
      <DataGrid dataSource={users} >
        <Column dataField="activity" />
        {/* <Column dataField="labelid" /> */}
        <Column dataField="date" />
        <Column dataField="time" />
        {log == false && <Column dataField="Details" encodeHtml={false} />}
        {log == true && <Column dataField="SpecificDetails" encodeHtml={false} />}
        <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
        <Paging defaultPageSize={5} />
        {/* <FilterRow visible={true} /> */}
        {/* <Lookup /> */}
        {/* <Export enabled={true} /> */}
        {/* <HeaderFilter visible={true} /> */}
      </DataGrid>
      <Button style={{ margin: "20px", float: "right" }} width={70} onClick={ChangeLog}>Log</Button>
    </React.Fragment>
  );
}