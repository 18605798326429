import { HomePage, TasksPage, CustomerPage, CustomerPatientPage, CustomerDoctorPage, AddUsersPage, AddCustomerPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/customer',
        element: CustomerPage
    },
    {
        path: '/customerpatient',
        element: CustomerPatientPage
    },
    {
        path: '/customerdoctor',
        element: CustomerDoctorPage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/addusers',
        element: AddUsersPage
    },
    {
        path: '/addcustomer',
        element: AddCustomerPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
