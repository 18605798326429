export const CPnavigation = [
    {
      text: 'Home',
      path: '/home',
      icon: 'home'
    },
    {
      text: 'Customer',
      path: '/customer',
      icon: 'floppy'
    },
    {
      text: 'Add Customer',
      path: '/addcustomer',
      icon: 'floppy'
    }
    ];
  