import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect, useRef, useCallback } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { DoctorListDatagrid, AllPatientListDatagrid, AddDoctorComponent, AddEndUserComponent } from '../../components';
  import Form, {
    Item as FormItem,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule
  } from 'devextreme-react/form';
  
  export default function AddUsersPage() {
  

    // const formData = useRef({ usename: '', password: '' });

    // const onSubmit = async (e) => {
    //     e.preventDefault();
    //     // console.log("submit")
    //   };

    // const confirmPassword = useCallback(
    //     ({ value }) => value === formData.current.password,
    //     []
    //   );

    return (
      <React.Fragment>
        <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true}>
          <Item title="Add Doctor" icon="floppy"> 
                <AddDoctorComponent />
            </Item>
  
            <Item title="Add Patient" icon="floppy"> 
              <AddEndUserComponent />
            </Item>
          </TabPanel>
          
  
      </React.Fragment>
    );
  }
