export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Customer',
    path: '/customer',
    icon: 'floppy'
  },
  {
    text: 'Add Users',
    path: '/addusers',
    icon: 'floppy'
  },
  // {
  //   text: 'Add Customer',
  //   path: '/addcustomer',
  //   icon: 'floppy'
  // }
  ];
