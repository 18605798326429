import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Export,
  HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './DoctorListDatagrid.css';

export default function DoctorListDatagrid() {

  const { user } = useAuth();

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);

  function handleRowClick(e) {
    const userToken = e.data.usertoken;
    const userName = e.data.name;
    navigate('/customerdoctor', { state: { userToken: userToken, name: userName } });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(backendlink + '/msg/getdoctorlist',
          {
            usertoken: user.usertoken
          },
          {
            headers: {
              token: user.token
            }
          });
        if (response.data.errorno == 0) {
          const outputArray = response.data.result.map(item => {
            // const customer = item.name.split('-')[0];
            return { customer: item.parentname, name: item.name, email: item.email, usertoken: item.usertoken };
          });
          setUsers(outputArray)
        }
        else {
          alert(response.data.errmessage);
        }
      } catch (error) {
        // console.log(error);
        alert(error);
      }
    };

    fetchData();
  }, [user]);


  return (
    <React.Fragment>
      <DataGrid dataSource={users} onRowClick={handleRowClick}>
        <Column dataField='customer' />
        <Column dataField="name" />
        <Column dataField="email" />
        {/* <Column dataField="type" />
            <Column dataField="created" /> */}
        <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
        <Paging defaultPageSize={5} />
        <FilterRow visible={true} />
        <Lookup />
        {/* <Export enabled={true} /> */}
        {/* <HeaderFilter visible={true} /> */}
      </DataGrid>
    </React.Fragment>
  )

}
