import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Export,
  HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import { DoctorListDatagrid, AllPatientListDatagrid } from '../../components';

export default function CustomerPage() {

  return (
    <React.Fragment>
      <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true}>
        <Item title="Doctor" icon="floppy"> 
            <DoctorListDatagrid />
          </Item>

          <Item title="Patient" icon="floppy"> 
            <AllPatientListDatagrid />
          </Item>
        </TabPanel>
        

    </React.Fragment>
  );
}