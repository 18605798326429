// import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
// import { getUser, signIn as sendSignInRequest } from '../api/auth';


// function AuthProvider(props) {
//   const [user, setUser] = useState();
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     (async function () {
//       const result = await getUser();
//       if (result.isOk) {
//         setUser(result.data);
//       }

//       setLoading(false);
//     })();
//   }, []);

//   const signIn = useCallback(async (email, password) => {
//     const result = await sendSignInRequest(email, password);
//     if (result.isOk) {
//       setUser(result.data);
//     }

//     return result;
//   }, []);

//   const signOut = useCallback(() => {
//     setUser(undefined);
//   }, []);


//   return (
//     <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
//   );
// }

// const AuthContext = createContext({ loading: false });
// const useAuth = () => useContext(AuthContext);

// export { AuthProvider, useAuth }


import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
// import { getUser, signIn as sendSignInRequest } from '../api/auth';
import axios from "axios";
import { backendlink } from '../config';
import Cookies from 'js-cookie';

function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // (async function () {
    //   const result = await getUser();
    //   if (result) {
    //     setUser(result.data);
    //   }

    setLoading(false);
    // })();
  }, []);

  const signIn = useCallback(async (email, password) => {
    // console.log("hdsadsa", email, password)
    try {
      const result = await axios.post(backendlink + '/user/signin', {
        username: email,
        password: password
      })
      if (result.data.errorno == 0) {
        setUser(result.data.result);
        Cookies.set("Malauser", JSON.stringify(result.data.result), { expires: 7 });

      }
      // else {
      //   alert(result.data.errmessage);
      // }
      return result.data;
    }
    catch (error) {
      if (error.response.data.message)
        return { "error": error.response.data.message }
      else {
        return { "error": error }
      }

    }
    // const result = await sendSignInRequest(email, password);
  }, []);

  const signOut = useCallback(() => {
    // console.log("signed out")
    setUser(undefined);
    Cookies.remove("Malauser");
  }, []);


  return (
    <AuthContext.Provider value={{ user, setUser, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
