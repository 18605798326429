import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  Export,
  HeaderFilter
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { backendlink } from '../../config';

export default function CustomerDoctorPage() {

  const { user } = useAuth();

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);

  const [doctor, setdoctor] = useState("")

  const location = useLocation();

  function handleRowClick(e) {
    const userToken = e.data.usertoken;
    const userName = e.data.name;
    const userEmail = e.data.email;
    const userPhone = e.data.phone;
    navigate('/customerpatient', { state: { name: userName, userToken: userToken, email: userEmail, phone: userPhone } });
  }

  useEffect(() => {
    // console.log(location.state.userToken)
    setdoctor(location.state.name);
    const fetchData = async () => {
      try {
        const response = await axios.post(backendlink + '/msg/getpatientlistfromdoctor',
          {
            usertoken: location.state.userToken
          },
          {
            headers: {
              token: user.token
            }
          });
        if (response.data.errorno == 0) {
          // console.log("XXX", response)
          setUsers(response.data.result)
        }
        else {
          alert(response.data.errmessage);
        }
      } catch (error) {
        // console.log(error);
        alert(error);
      }
    };

    fetchData();
    // console.log(doctor);
  }, []);

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Doctor: {doctor}</h2>
      <DataGrid dataSource={users} onRowClick={handleRowClick}>
        {/* <Column dataField='customer' /> */}
        <Column dataField="name" />
        <Column dataField="email" />
        <Column dataField="usertype" />
        {/* <Column dataField="created" /> */}
        <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
        <Paging defaultPageSize={5} />
        {/* <FilterRow visible={true} /> */}
        {/* <Lookup /> */}
        {/* <Export enabled={true} /> */}
        {/* <HeaderFilter visible={true} /> */}
      </DataGrid>
    </React.Fragment>
  );
}