import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';

  export default function AllPatientListDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();
  
    const [users, setUsers] = useState([]);

      function handleRowClickPatient(e) {
        const userToken = e.data.usertoken;
        const userName = e.data.name;
        const userEmail = e.data.email;
        const userPhone = e.data.phone;
        navigate('/customerpatient', { state: { name: userName, email: userEmail, userToken: userToken, phone: userPhone } });
      }

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post(backendlink + '/msg/getpatientlistfromcustomer',
              {
                usertoken: user.usertoken
              }, 
              {
                headers: {
                  token: user.token
                }        
            });
            // console.log(response)
          if (response.data.errorno == 0){
            setUsers(response.data.result)
          }        
          else{
            alert(response.data.errmessage);
          }
          } catch (error) {
            // console.log(error);
            alert(error);
          }
        };
      
        fetchData();
      }, []);
    

    return(
        <React.Fragment>
            <DataGrid dataSource={users} onRowClick={handleRowClickPatient}>
            <Column dataField="name" />
            <Column dataField="email" />
            <Column dataField="usertype" />
            {/* <Column dataField="created" /> */}
            <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
            <Paging defaultPageSize={5} />
            <FilterRow visible={true} />
            <Lookup />
            {/* <Export enabled={true} /> */}
            {/* <HeaderFilter visible={true} /> */}
          </DataGrid>
        </React.Fragment>
    )

  }
